import { FormattedMessage } from 'react-intl';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.sass';

const Features = () => (
  <section className={styles.featuresSection}>
    <div className={styles.featuresCol}>
      <div className={cx('last-score', styles.featuresImage)} />
      <div className={styles.featuresText}>
        <h6 className="bigTitle feature">
          <FormattedMessage id="features.tracking.title" />
        </h6>
        <p>
          <FormattedMessage id="features.tracking.body" />
        </p>
      </div>
    </div>
    <div className={styles.featuresCol}>
      <div className={styles.featuresText}>
        <h6 className="bigTitle feature">
          <FormattedMessage id="features.modality.title" />
        </h6>
        <p>
          <FormattedMessage id="features.modality.body" />
        </p>
      </div>
      <div className={cx('calendar-graph', styles.featuresImage)} />
    </div>
    <div className={styles.featuresCol}>
      <div className={cx('community', styles.featuresImage)} />
      <div className={styles.featuresText}>
        <h6 className="bigTitle feature">
          <FormattedMessage id="features.scheme.title" />
        </h6>
        <p>
          <FormattedMessage id="features.scheme.body" />
        </p>
      </div>
    </div>
  </section>
);

Features.propTypes = {};

export default Features;
