import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import styles from './index.module.sass';

class Metrics extends Component {
  state = {
    metrics: {
      result: 0,
      wod: 0,
      user: 0
    },
    error: null
  };

  componentDidMount() {
    this.fetchMetrics();
  }

  render() {
    const { metrics } = this.state;

    if (this.state.error) return <span />;

    return (
      <section className={styles.metricsSection}>
        <div className={styles.metricsOuter}>
          <div className={styles.metricsCol}>
            <span className="bigTitle">{metrics.result}</span>
            <h5>
              <FormattedMessage id="metric.results" />
            </h5>
          </div>
          <div className={styles.metricsCol}>
            <span className="bigTitle">{metrics.wod}</span>
            <h5>
              <FormattedMessage id="metric.wods" />
            </h5>
          </div>
          <div className={styles.metricsCol}>
            <span className="bigTitle">{metrics.user}</span>
            <h5>
              <FormattedMessage id="metric.modality" />
            </h5>
          </div>
        </div>
      </section>
    );
  }

  fetchMetrics = () => {
    axios
      .get(`https://app.herosnatch.com/api/dashboard/metrics/`)
      .then(res => {
        this.setState({
          error: null,
          metrics: res.data
        });
      })
      .catch(error => this.setState({ error }));
  };
}

Metrics.propTypes = {};

export default Metrics;
