import { injectIntl, intlShape } from 'react-intl';

import Compatibility from '../Compatibility';
import Features from '../Features';
// import News from '../News';
import Footer from '../Footer';
import JoinUs from '../JoinUs';
import Metrics from '../Metrics';
import Pricing from '../Pricing';
// import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
// import cx from 'classnames';
// import styles from './index.module.sass';
import Promotion from '../Promotion';
import React from 'react';
import Testimonials from '../Testimonials';

const propTypes = {
  intl: intlShape.isRequired
};
class Main extends React.Component {
  // constructor(props){
  //   super(props);
  // }

  render() {
    return (
      <div className={this.props.intl.locale}>
        <Promotion />
        <Metrics />
        <Features />
        <Pricing />
        <JoinUs />
        <Compatibility />
        <Testimonials />
        {/* <News/> */}
        <Footer />
      </div>
    );
  }
}

Main.propTypes = propTypes;

export default injectIntl(Main);
