import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { FEATURES } from './features';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.sass';

class Pricing extends React.Component {
  getFeatureAvailability(availability) {
    if (availability === true) {
      return <FontAwesomeIcon icon={faCheck} size="1x" />;
    } else if (availability === false) {
      return <FontAwesomeIcon className={styles.grey} icon={faTimes} size="lg" />;
    } else {
      return <FormattedMessage id={availability} />;
    }
  }

  showIntercomeWidget() {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }

  render() {
    const features = FEATURES;
    return (
      <section className={styles.pricing}>
        <h2 className={styles.pricing__title}>
          <FormattedMessage id="pricing.title" />
        </h2>
        <div className={styles.pricing__inner}>
          <table>
            <thead>
              <tr>
                <td />
                <td className={styles.t_header_basic}>
                  <FormattedMessage id="pricing.basic" />
                </td>
                <td className={styles.t_header_athlete}>
                  <FormattedMessage id="pricing.athlete" />
                </td>
                <td className={styles.t_header_coach}>
                  <FormattedMessage id="pricing.coach" />
                </td>
              </tr>
            </thead>
            <tbody>
              {features.map((f, i) => (
                <tr key={i}>
                  <td>
                    <span>
                      <FormattedMessage id={f.name} />
                    </span>
                    {f.tooltip && (
                      <FontAwesomeIcon
                        title={this.context.intl.formatMessage({ id: f.tooltip })}
                        className={styles.tooltip_icon}
                        icon={faInfoCircle}
                        size="lg"
                      />
                    )}
                  </td>
                  <td>{this.getFeatureAvailability(f.basic)}</td>
                  <td>{this.getFeatureAvailability(f.athlete)}</td>
                  <td>{this.getFeatureAvailability(f.coach)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td>
                  <FormattedMessage id="pricing.priceBasic" />
                </td>
                <td>
                  <div>
                    <FormattedMessage id="pricing.priceAthlete" />
                  </div>
                  <small>
                    <FormattedMessage id="pricing.perMonth" />
                  </small>
                </td>
                <td>
                  <div>
                    <FormattedMessage id="pricing.priceCoach" />
                  </div>
                  <small>
                    <FormattedMessage id="pricing.perAthleteMonth" />
                  </small>
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  {/* Intercom('show'); */}
                  <button
                    className="hs-button"
                    onClick={navigate.bind(this, 'https://app.herosnatch.com')}
                  >
                    <FormattedMessage id="pricing.getStarted" />
                  </button>
                </td>
                <td>
                  <button type="button" className="hs-button" onClick={this.showIntercomeWidget}>
                    <FormattedMessage id="pricing.contactUs" />
                  </button>
                </td>
                <td>
                  <button type="button" className="hs-button" onClick={this.showIntercomeWidget}>
                    <FormattedMessage id="pricing.contactUs" />
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
          <div className={styles.cards}>
            <div className={cx(styles.card, styles.t_header_basic)}>
              <header>
                <div>
                  <FormattedMessage id="pricing.basic" />
                </div>
                <small>
                  <FormattedMessage id="pricing.basicFor" />
                </small>
              </header>
              <article>
                <h3>
                  <FormattedMessage id="pricing.priceBasic" />
                </h3>
                <ul className={cx(styles.list_features)}>
                  <li>
                    <FormattedMessage id="pricing.feature.unlimited" />{' '}
                    <FormattedMessage id="pricing.feature.workoutsTracking" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.workoutLibrary" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.personalRecords" />
                  </li>
                  <li>
                    20 <FormattedMessage id="pricing.feature.publicWorkouts" />
                  </li>
                  <li>
                    3 <FormattedMessage id="pricing.feature.weeksHistory" />
                  </li>
                </ul>
              </article>
              <footer>
                <button
                  type="button"
                  className="hs-button green"
                  onClick={navigate.bind(this, 'https://app.herosnatch.com')}
                >
                  <FormattedMessage id="pricing.getStarted" />
                </button>
              </footer>
            </div>
            <div className={cx(styles.card, styles.t_header_athlete)}>
              <header>
                <div>
                  <FormattedMessage id="pricing.athlete" />
                </div>
                <small>
                  <FormattedMessage id="pricing.athleteFor" />
                </small>
              </header>
              <article>
                <h3>
                  <FormattedMessage id="pricing.priceAthlete" />
                </h3>
                <small>
                  <FormattedMessage id="pricing.perMonth" />
                </small>
                <ul className={cx(styles.list_features)}>
                  <li>
                    <strong>
                      <FormattedMessage id="pricing.feature.allFromPlanBasic" />
                    </strong>
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.unlimited" />{' '}
                    <FormattedMessage id="pricing.feature.publicWorkouts" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.unlimited" />{' '}
                    <FormattedMessage id="pricing.feature.privateWorkouts" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.privateScoring" />{' '}
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.unlimitedHistory" />{' '}
                  </li>
                </ul>
              </article>
              <footer>
                <button
                  type="button"
                  className="hs-button green"
                  onClick={this.showIntercomeWidget}
                >
                  <FormattedMessage id="pricing.contactUs" />
                </button>
              </footer>
            </div>
            <div className={cx(styles.card, styles.t_header_coach)}>
              <header>
                <div>
                  <FormattedMessage id="pricing.coach" />
                </div>
                <small>
                  <FormattedMessage id="pricing.coachFor" />
                </small>
              </header>
              <article>
                <h3>
                  <FormattedMessage id="pricing.priceCoach" />
                </h3>
                <small>
                  <FormattedMessage id="pricing.perAthleteMonth" />
                </small>
                <ul className={cx(styles.list_features)}>
                  <li>
                    <strong>
                      <FormattedMessage id="pricing.feature.allFromPlanAthlete" />{' '}
                    </strong>
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.programBuilder" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.membershipManagement" />
                  </li>
                  <li>
                    <FormattedMessage id="pricing.feature.memberNotifications" />
                  </li>
                </ul>
              </article>
              <footer>
                <button
                  type="button"
                  className="hs-button green"
                  onClick={this.showIntercomeWidget}
                >
                  <FormattedMessage id="pricing.contactUs" />
                </button>
              </footer>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = {};
Pricing.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default Pricing;
