import { FormattedMessage } from 'react-intl';
import React from 'react';
import styles from './index.module.sass';

class JoinUs extends React.Component {
  trackAnalytics() {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('ref');
    window.analytics.track('sign_up_click', {
      referrer: referrer,
      organic: !referrer
    });
  }
  render() {
    return (
      <section className={styles.footer}>
        <div className={styles.footer__outer}>
          <h2 className={styles.footer__title}>
            <FormattedMessage id="joinus.title" />
          </h2>
          <div className={styles.footer__actions} onClick={this.trackAnalytics}>
            <a
              href="https://app.herosnatch.com/onboarding"
              onClick={this.trackAnalytics}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="hs-button lg green">
                <FormattedMessage id="main.tryIt" />
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

JoinUs.propTypes = {};

export default JoinUs;
