export const FEATURES = [
  {
    name: 'pricing.feature.workoutsTracking',
    tooltip: 'pricing.feature.workoutsTrackingTooltip',
    basic: 'pricing.feature.unlimited',
    athlete: 'pricing.feature.unlimited',
    coach: 'pricing.feature.unlimited'
  },
  {
    name: 'pricing.feature.workoutLibrary',
    tooltip: 'pricing.feature.workoutLibraryTooltip',
    basic: true,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.workoutBuilder',
    tooltip: null,
    basic: true,
    athlete: true,
    coach: true
  },
  // {
  //   name: "pricing.feature.weightCalculator",
  //   tooltip: null,
  //   basic: true,
  //   athlete: true,
  //   coach: true
  // },
  {
    name: 'pricing.feature.weeklyReporting',
    tooltip: null,
    basic: true,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.personalRecords',
    tooltip: null,
    basic: true,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.personalWorkoutLibrary',
    tooltip: 'pricing.feature.personalWorkoutLibraryTooltip',
    basic: true,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.activityFeed',
    tooltip: 'pricing.feature.activityFeedTooltip',
    basic: true,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.publicWorkouts',
    tooltip: 'pricing.feature.publicWorkoutsTooltip',
    basic: '20',
    athlete: 'pricing.feature.unlimited',
    coach: 'pricing.feature.unlimited'
  },
  {
    name: 'pricing.feature.privateWorkouts',
    tooltip: null,
    basic: false,
    athlete: 'pricing.feature.unlimited',
    coach: 'pricing.feature.unlimited'
  },
  {
    name: 'pricing.feature.privateScoring',
    tooltip: 'pricing.feature.privateScoringTooltip',
    basic: false,
    athlete: true,
    coach: true
  },
  {
    name: 'pricing.feature.resultAnalysis',
    tooltip: 'pricing.feature.resultAnalysisTooltip',
    basic: 'pricing.feature.resultAnalysisBasic',
    athlete: 'pricing.feature.unlimitedHistory',
    coach: 'pricing.feature.unlimitedHistory'
  },
  {
    name: 'pricing.feature.programBuilder',
    tooltip: null,
    basic: false,
    athlete: 'pricing.feature.unlimitedAccess',
    coach: 'pricing.feature.unlimitedAccess'
  },
  {
    name: 'pricing.feature.membershipManagement',
    tooltip: 'pricing.feature.membershipManagementTooltip',
    basic: false,
    athlete: false,
    coach: true
  },
  {
    name: 'pricing.feature.memberNotifications',
    tooltip: null,
    basic: false,
    athlete: false,
    coach: true
  }
];
