import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { PureComponent } from 'react';

import SlickSlider from 'react-slick';

class Slider extends PureComponent {
  state = {
    isClient: false
  };

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  slickNext() {
    this.slider.slickNext();
  }

  slickPrev() {
    this.slider.slickPrev();
  }

  render() {
    const { children, responsive, ...rest } = this.props;
    const { isClient } = this.state;

    return (
      <SlickSlider
        key={isClient ? 'client' : 'server'}
        responsive={isClient ? responsive : null}
        ref={c => (this.slider = c)}
        {...rest}
      >
        {children}
      </SlickSlider>
    );
  }
}

export default Slider;
