import { FormattedMessage } from 'react-intl';
import React from 'react';
import Slider from '../Slider';
import author1 from '../../img/testimonials/author1.png';
import author2 from '../../img/testimonials/author2.png';
import author3 from '../../img/testimonials/author3.png';
import styles from './index.module.sass';

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '650px',
      slidesToShow: 1,
      speed: 300,
      responsive: [
        {
          breakpoint: 1999,
          settings: {
            centerPadding: '525px'
          }
        },
        {
          breakpoint: 1600,
          settings: {
            centerPadding: '405px'
          }
        },
        {
          breakpoint: 1366,
          settings: {
            centerPadding: '305px'
          }
        },
        {
          breakpoint: 1180,
          settings: {
            centerPadding: '170px'
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: true,
            centerPadding: '0'
          }
        }
      ]
    };
    return (
      <section className={styles.testimonials}>
        <h2 className={styles.testimonials__title}>
          <FormattedMessage id="testimonials.title" />
          <div className={styles.testimonials__nav}>
            <button type="button" onClick={this.previous} />
            <button type="button" onClick={this.next} />
          </div>
        </h2>
        <div className={styles.testimonials__outer}>
          <Slider ref={c => (this.slider = c)} {...settings}>
            <div className={styles.testimonials__col}>
              <div className={styles.testimonials__col_inner}>
                <div className={styles.testimonials__img}>
                  <img src={author1} alt="" />
                </div>
                <div className={styles.testimonials__author}>
                  <div className={styles.testimonials__author_name}>
                    <FormattedMessage id="testimonials.author1" />
                  </div>
                  <div className={styles.testimonials__position}>
                    <FormattedMessage id="testimonials.role1" />
                  </div>
                </div>
              </div>

              <div className={styles.testimonials__text}>
                <p>
                  <FormattedMessage id="news.cardContent1" />
                </p>
              </div>
            </div>
            <div className={styles.testimonials__col}>
              <div className={styles.testimonials__col_inner}>
                <div className={styles.testimonials__img}>
                  <img src={author2} alt="" />
                </div>
                <div className={styles.testimonials__author}>
                  <div className={styles.testimonials__author_name}>
                    <FormattedMessage id="testimonials.author2" />
                  </div>
                  <div className={styles.testimonials__position}>
                    <FormattedMessage id="testimonials.role2" />
                  </div>
                </div>
              </div>
              <div className={styles.testimonials__text}>
                <p>
                  <FormattedMessage id="news.cardContent2" />
                </p>
              </div>
            </div>
            <div className={styles.testimonials__col}>
              <div className={styles.testimonials__col_inner}>
                <div className={styles.testimonials__img}>
                  <img src={author3} alt="" />
                </div>
                <div className={styles.testimonials__author}>
                  <div className={styles.testimonials__author_name}>
                    <FormattedMessage id="testimonials.author3" />
                  </div>
                  <div className={styles.testimonials__position}>
                    <FormattedMessage id="testimonials.role3" />
                  </div>
                </div>
              </div>
              <div className={styles.testimonials__text}>
                <p>
                  <FormattedMessage id="news.cardContent3" />
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}

Testimonials.propTypes = {};

export default Testimonials;
