import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import styles from './index.module.sass';
import thumb from '../../img/thumb-up.png';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const placeholder = defineMessages({
  textarea: {
    id: 'feedback.textarea'
  }
});
class FormModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    isSubmitted: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      submitted: false
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  UNSAFE_componentWillMount() {
    if (typeof window !== 'undefined') {
      Modal.setAppElement('body');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ submitted: nextProps.isSubmitted });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => {
        this.setState({ submitted: true });
        // navigate('.');
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const submitHidden = !this.state.submitted ? styles.hidden : '';
    const formHidden = this.state.submitted ? styles.hidden : '';

    const { intl } = this.props;

    return (
      <Modal
        use
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={this.props.isOpen}
        onRequestClose={() => navigate('.')}
        contentLabel="Modal"
      >
        <div className={cx(styles['modal__submitted'], submitHidden)}>
          <h2>
            <FormattedMessage id="feedback.thanks" />
          </h2>
          <img className={styles['modal__submitted-img']} src={thumb} alt="" />
        </div>

        <h2 className={formHidden}>
          <FormattedMessage id="feedback.title" />
        </h2>
        <button type="button" className={styles.close} onClick={() => navigate('.')} />
        <form
          method="post"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit.bind(this)}
          className={cx(styles['contact-form'], formHidden)}
        >
          <input type="hidden" name="form-name" value="contact" />
          <input
            type="email"
            name="email"
            required
            placeholder="E-mail"
            onChange={this.handleChange}
          />
          <textarea
            rows="4"
            cols="50"
            name="message"
            placeholder={intl.formatMessage(placeholder.textarea)}
            onChange={this.handleChange}
          />
          <button className={cx('hs-button', styles['contact-form__submit'])} type="submit">
            <FormattedMessage id="feedback.btn" />
          </button>
        </form>
      </Modal>
    );
  }
}

export default injectIntl(FormModal);
