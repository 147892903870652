import 'intl';
import './all.sass';

import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import Modal from '../FormModal';
import PropTypes from 'prop-types';
import React from 'react';
import { getCurrentLangKey } from 'ptz-i18n';

const TemplateWrapper = ({ children, data, location, i18nMessages }) => {
  const url = location.pathname;
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  // const homeLink = `/${langKey}/`;
  // const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url));
  let isModal = true;
  let isOpen = !!location.search.feedback;

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <div>
        <Helmet
          title="HeroSnatch: WOD tracker, online coaching, programming"
          meta={[
            {
              name: 'description',
              content:
                'Start tracking workouts today. Best workouts tracking app and coaching platform'
            },
            { name: 'keywords', content: 'herosnatch, crossfit' },
            {
              name: 'google-site-verification',
              content: 'iMyUv7hlq0jCKi2i-Lb6-Fy9YNPDKFwSl1JIdq1Q7YA'
            },
            {
              name: 'google-site-verification',
              content: 'Jnvgf0ruV28X3-hX9hAza90IlKybfCI-lwAosPLnN60'
            }
          ]}
        />
        <div>
          {children}
          <div>{isModal && <Modal location={location} isOpen={isOpen} isSubmitted={false} />}</div>
        </div>
      </div>
    </IntlProvider>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default TemplateWrapper;
