import { FormattedMessage } from 'react-intl';
import React from 'react';
import android from '../../img/android.svg';
import devices from '../../img/devices.png';
import ios from '../../img/ios.svg';
// import cx from 'classnames';
import styles from './index.module.sass';
import web from '../../img/web.svg';

const Compatibility = () => (
  <section className={styles.compatibilitySection}>
    <div className={styles.compatibilityOuter}>
      <div className={styles.compatibilityInner}>
        <div className={styles.compatibilityAppsOuter}>
          <h2 className={styles.compatibilityTitle}>
            <FormattedMessage id="compatibility.title" />
          </h2>
          <ul className={styles.compatibilityApps}>
            <li>
              <a
                className={styles.web}
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.herosnatch.com"
              >
                <img src={web} alt="" />
                <span>WEB</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://itunes.apple.com/us/app/herosnatch/id1447158929?ls=1&mt=8"
              >
                <img src={ios} alt="" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.herosnatch.app"
              >
                <img target="_blank" src={android} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.compatibilityImage}>
          <img src={devices} alt="" />
        </div>
      </div>
    </div>
  </section>
);

Compatibility.propTypes = {};

export default Compatibility;
