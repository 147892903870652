import { FormattedMessage } from 'react-intl';
import Navbar from '../Navbar';
import React from 'react';
import cx from 'classnames';
import styles from './index.module.sass';

class Promotion extends React.Component {
  trackAnalytics() {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('ref');
    window.analytics.track('sign_up_click', {
      referral_utm_source: referrer,
      organic: !referrer
    });
  }

  render() {
    return (
      <section className={styles.promoSection}>
        <div className="container">
          <Navbar />
          <h1 className={cx('display-linebreak', styles.promoTitle)}>
            <FormattedMessage id="main.youCanDoIt" />
          </h1>
          <h2 className={cx('display-linebreak', styles.promoSubtitle)}>
            <FormattedMessage id="main.trackIt" />
          </h2>
          <div className={cx('display-linebreak', styles.promoFooter)}>
            <a
              href="https://app.herosnatch.com/onboarding"
              onClick={this.trackAnalytics}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="hs-button lg">
                <FormattedMessage id="main.tryIt" />
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

Promotion.propTypes = {};

export default Promotion;
